import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Form,
  Input,
  Button,
  Space,
  Divider,
  TreeSelect,
  InputNumber,
  Select,
  Collapse,
  Panel,
  List,
  Drawer
} from "antd";

import axios from "axios";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { decode, encode } from "@googlemaps/polyline-codec";
import { map } from "leaflet";

import { AiOutlineDown } from "react-icons/ai";

const { Option } = Select;

const RouteOptimization = ({
  unassigned_orders = [],
  agentsArray = [],
  freeAgentsArray = [],
  busyAgentsArray = [],
  setMapRoute,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [optimizedData, setOptimizedData] = useState([]);

  const orders = Array.from(unassigned_orders, (order, i) => ({
    title: order.client_order_identifier,
    value: order.id,
  }));

  const optimize = () => {
    setButtonLoading(true);
    axios
      .post(
        `https://fleet.howincloud.com/api/v1/${sessionStorage.getItem(
          "tenant"
        )}/optimize-orders`,
        {
          order_ids: selectedValues,
          token: sessionStorage.getItem("auth_token"),
          agent_ids: selectedAgents,
        }
      )
      .then((response) => {
        setButtonLoading(false);
        setOptimizedData(response.data);

        // if (response.data.success) {
        //   response.data.routes.forEach((route) => {
        //     // Iterating Through Decoded PolyLine Routes and creating Google Supported LatLng Object
        //     if (route.geometry) {
        //       let array = decode(route.geometry);
        //       let newArray = [];
        //       array.forEach((element) => {
        //         let loopArray = {
        //           lat: element[0],
        //           lng: element[1],
        //         };

        //         newArray.push(loopArray);
        //       });

        //       setMapRoute(newArray);
        //     }
        //   });
        // }
      });
  };

  const agents = Array.from(agentsArray, (agent, i) => ({
    title: `${agent.name} | ${agent.busy ? "BUSY" : "FREE"}`,
    value: agent.id,
  }));

  const allIds = unassigned_orders.map(({ id }) => id);
  const agentIds = agentsArray.map(({ id }) => id);

  const freeAgentIds = freeAgentsArray.map(({ id }) => id);
  const busyAgentIds = busyAgentsArray.map(({ id }) => id);

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  const showOnMap = (agent) => {
    console.log(`show on map`);
  };
  return (
    <>
      {/* {JSON.stringify(unassigned_orders)} */}

     
      
      <div className='mb-2'>Select Tasks</div>
      <TreeSelect
        size={"large"}
        allowClear={true}
        placeholder='Select Tasks to be optimized'
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        style={{ width: "350px" }}
        dropdownStyle={{ maxHeight: "300px" }}
        onChange={(ids) => setSelectedValues(ids)}
        value={selectedValues}
        maxTagCount={5}
        maxTagPlaceholder={(omittedValues) =>
          `+ ${omittedValues.length} Tasks ...`
        }
        treeData={[
          {
            title:
              selectedValues.length > 0 ? (
                <span
                  onClick={() => setSelectedValues([])}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer",
                  }}>
                  Unselect all
                </span>
              ) : (
                <span
                  onClick={() => setSelectedValues(allIds)}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer",
                  }}>
                  Select all
                </span>
              ),
            value: "xxx",
            disableCheckbox: true,
            disabled: true,
          },
          ...orders,
        ]}
      />

      <div className='mt-2 mb-2'>Select Agents</div>
      <TreeSelect
        allowClear={true}
        size={"large"}
        placeholder='Select Agents to be included in the optimization'
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        style={{ width: "350px" }}
        dropdownStyle={{ maxHeight: "300px" }}
        onChange={(ids) => setSelectedAgents(ids)}
        value={selectedAgents}
        maxTagCount={5}
        maxTagPlaceholder={(omittedValues) =>
          `+ ${omittedValues.length} Agents ...`
        }
        treeData={[
          {
            title:
              selectedAgents.length > 0 ? (
                <span
                  onClick={() => setSelectedAgents([])}
                  style={{
                    color: "#286FBE",
                    cursor: "pointer",
                  }}>
                  Unselect all
                </span>
              ) : (
                <span
                  onClick={() => setSelectedAgents(agentIds)}
                  style={{
                    color: "#286FBE",
                    cursor: "pointer",
                  }}>
                  Select all
                </span>
              ),
            value: "xxx",
            disableCheckbox: true,
            disabled: true,
          },
          {
            title: (
              <span
                onClick={() => setSelectedAgents(freeAgentIds)}
                style={{
                  color: "#286FBE",
                  cursor: "pointer",
                }}>
                Select Free ({freeAgentsArray.length})
              </span>
            ),
            value: "free",
            disableCheckbox: true,
            disabled: true,
          },
          {
            title: (
              <span
                onClick={() => setSelectedAgents(busyAgentIds)}
                style={{
                  color: "#286FBE",
                  cursor: "pointer",
                }}>
                Select Busy ({busyAgentsArray.length})
              </span>
            ),
            value: "busy",
            disableCheckbox: true,
            disabled: true,
          },
          ...agents,
        ]}
      />

      {/* <div onClick={() => optimize()}>CLICK</div> */}
      <Divider />
      {/* <Form.Item> */}
      <Button type='primary' onClick={() => optimize()} loading={buttonLoading}>
        {buttonLoading == true ? "AI Taking charge" : "Optimize Route"}
      </Button>

      {optimizedData.success ? (
        <>
          <Divider />

        <div className="d-flex">
        <div className='mb-2'>AI Optimized Routes
          <br/>
            <span style={{color:'grey'}}>Agents are assigned with appropriate routes.</span>
          </div>
          <Button type='primary'>
            Execute Assignation
          </Button>

        </div>
          
          <Collapse defaultActiveKey={["0"]}
          style={{                      borderRadius: "10px",
          marginTop: "10px",

          color:'#fff'}}>
            {optimizedData?.agents_data &&
              optimizedData?.agents_data.map((agent, index) => (
                <Collapse.Panel  
                showArrow={false}

                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  fontWeight: "700",
                  borderRadius: "10px",
                  marginTop: index > 0 ? "10px" : "0px"

                }} 
                header={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span style={{ color: "#fff" }}>{agent.name}</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}>
                      <AiOutlineDown color='#fff' size={20} width={20} />
                    </div>
                  </div>
                } key={index}>

                  <div className="d-flex justify-content-end">
                  <Button onClick={() => showOnMap(agent)}>Show on Map</Button>

                  </div>
                  <Collapse
                    style={{
                      backgroundColor: "#1e79dc",
                      marginTop: "10px",
                      color: "#fff",
                      fontWeight: "700",
                      borderRadius: "10px",
                    }}>
                    <Collapse.Panel
                      showArrow={false}
                      header={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <span style={{ color: "#fff" }}>Pickups</span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}>
                            <span
                              style={{
                                color: "#1e79dc",
                                backgroundColor: "#fff",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              {agent?.pickups?.length}
                            </span>
                            <AiOutlineDown color='#fff' size={20} width={20} />
                          </div>
                        </div>
                      }>
                      <List
                        itemLayout='horizontal'
                        dataSource={agent?.pickups}
                        renderItem={(item, index) => (
                          <List.Item>
                            <List.Item.Meta
                              title={`${index + 1} - ${
                                item.client_order_identifier
                              }`}
                            />
                            <p>
                              {item.pickup_name} | {item.pickup_contact}
                            </p>
                          </List.Item>
                        )}
                      />
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse
                    style={{
                      backgroundColor: "#134780",
                      marginTop: "10px",
                      color: "#fff",
                      fontWeight: "700",
                      borderRadius: "10px",
                    }}>
                    <Collapse.Panel
                      showArrow={false}
                      header={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <span style={{ color: "#fff" }}>Drops</span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}>
                            <span
                              style={{
                                color: "#134780",
                                backgroundColor: "#fff",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              {agent?.deliveries?.length}
                            </span>
                            <AiOutlineDown color='#fff' size={20} width={20} />
                          </div>
                        </div>
                      }
                      style={{ color: "white" }}>
                      <List
                        itemLayout='horizontal'
                        dataSource={agent?.deliveries}
                        renderItem={(item, index) => (
                          <List.Item>
                            <div>
                              <div>Priority : {index + 1}</div>
                              <div>
                                Task Name : {item.client_order_identifier}
                              </div>
                              <div>Drop Name : {item.customer_name}</div>
                              <div>Drop Name : {item.customer_phone}</div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </Collapse.Panel>
              ))}
          </Collapse>
        </>
      ) : (
        <div
          style={{
            marginTop: "15px",
            fontSize: "17px",
            fontWeight: "bold",
            color: "red",
          }}>
          Operation Failed
        </div>
      )}
    </>
  );
};
export default RouteOptimization;
