import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Form,
  Input,
  Button,
  Space,
  Divider,
  TreeSelect,
  InputNumber,
  Select,
} from "antd";

import {
  SelectMenu,
  Avatar,
  Badge,
  Pill,
  Position,
  Spinner,
  Alert,
  SearchInput,
} from "evergreen-ui";
import axios from "axios";

import {
  RiTeamFill,
  RiFlashlightFill,
  RiRouteFill,
  RiTrafficLightFill,
} from "react-icons/ri";
import ReactModal from "react-modal";

const { Option } = Select;

const Footer = ({
  cities,
  selectedCity,
  handleCity,
  setBatchMode,
  batchMode,
  setRouteOptimizePop,
  unassignedOrders,
  trafficLayer,
  setTrafficLayer,
}) => {
  const [openLockedModal, setopenLockedModal] = useState(false);

  const handleLockModal = () => {
    setopenLockedModal(!openLockedModal)
  }
  return (
    <>
      <div className='footer-container'>
        <div>
          <SelectMenu
            title={localStorage.getItem("select_team")}
            options={cities.map((city) => ({
              label: city.name,
              value: city.id,
            }))}
            style={{ zIndex: "9999999" }}
            position={Position.TOP}
            selected={selectedCity.label}
            onSelect={(item) => handleCity(item)}>
            {/* <Button>
            {this.state.selectedCity.label || (
              <> {localStorage.getItem("select_city")}</>
            )}
          </Button> */}
            <div className='footer-button'>
              <RiTeamFill style={{ width: "20px" }} />
              <span>
                {localStorage.getItem("select_team")} |{" "}
                {selectedCity.label || (
                  <> {localStorage.getItem("select_team")}</>
                )}
              </span>
            </div>
          </SelectMenu>
        </div>{" "}
        <ReactModal
          isOpen={openLockedModal}
          onRequestClose={handleLockModal}
          contentLabel="Example Modal"
          style={{
            content: {
              borderRadius: "20px"
            },
            overlay: {
              "background":"linear-gradient(to right, #2a5298, #1e3c72)"
      // opacity: "0.9"
    }
          }}
          className="bg-white rounded-4 p-3 mx-auto text-center"
          overlayClassName="d-flex align-items-center justify-content-center fixed-top fixed-bottom px-4 bg-dark opacity-10"
          appElement={document.getElementById("root")}
        >
          <img src={'/assets/locked.gif'} className=" mx-auto" alt="" style={{height:'300px'}} />
          <div className="fw-bold mt-3 text-dark">Success</div>
          <div className="small text-secondary my-2">
            ii
          </div>
          <button
            className="btn fleet_btn text-white px-3 py-2"
            onClick={handleLockModal}
          >
            Continue
          </button>
        </ReactModal>
        <div
          className='footer-button'
          style={batchMode ? { backgroundColor: "#fcb800" } : {}}
          onClick={() => setBatchMode(false)}>
          <RiFlashlightFill /> {localStorage.getItem("batch_mode")}
        </div>
        {/* <div
          className='footer-button'
          onClick={() => setRouteOptimizePop(false)}
          // onClick={() => handleLockModal()}

        >
          <RiRouteFill style={{ width: "20px" }} /> Auto Route Optimize (
          {unassignedOrders})
        </div> */}
        <div
          className='footer-button'
          style={trafficLayer ? { backgroundColor: "#fcb800" } : {}}
          onClick={() => setTrafficLayer(!trafficLayer)}>
          <RiTrafficLightFill style={{ width: "20px" }} /> {localStorage.getItem("traffic_data")}
        </div>
      </div>
    </>
  );
};
export default Footer;
