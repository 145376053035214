import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './App.css';
import Main from './components/Main';
import Page from './components/Errors/404';
import SessionExpired from './components/Errors/401';
import Portal from './components/Portal'
import TrackMyTask from './components/TrackMyTask'

import { BrowserRouter, Route, Switch } from "react-router-dom";


ReactDOM.render(
	<App>
		<BrowserRouter>
			<React.Fragment>
				<Switch>
					<Route path={"/"} exact component={Page} />
					<Route path={"/session-expierd"} exact component={SessionExpired} />
					<Route path={"/authenticate/:user_id/:token/:tenant"} exact component={Main} />
					<Route path={"/portal"} exact component={Portal} />
					<Route path={"/track-my-task/:tenant/:task_id"} exact component={TrackMyTask} />

				</Switch>
			</React.Fragment>
		</BrowserRouter>
	</App>,
	document.getElementById("root")
);