import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from "react-router";
import FeatherIcon from "feather-icons-react";
import Moment from "react-moment";
import * as moment from "moment";
import {
  Button,
  SelectMenu,
  Avatar,
  Badge,
  Pill,
  Position,
  Spinner,
  Alert,
  SearchInput,
} from "evergreen-ui";
import { Switch, Tooltip } from "antd";
import Ink from "react-ink";

import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';

export class Agents extends Component {

  state = {
    search_mode: false,
    tab_mode: true,
    free_agents: [],
    busy_agents: [],
    inactive_agents: [],
  }


  componentWillReceiveProps(props) {

    console.log('====================================');
    console.log(props, 'props');
    console.log('====================================');
    if (props.agents && props.agents.length > 0) {

      let free_agents = [];
      let busy_agents = [];
      let inactive_agentss = props.offline_agents;
      props.agents.map((agent, index) => {
        if (agent.on_duty == 1) {
          if (agent.accept_deliveries.length === 0  ) {
            free_agents.push(agent);
          } else {
            busy_agents.push(agent);
          }
        }
        
      });

      this.setState({ free_agents: free_agents, busy_agents: busy_agents, inactive_agents: inactive_agentss });
    }


  }





  render() {
    return (
      <React.Fragment>

        <div style={{ position: 'sticky', top: '0px', zIndex: '10', padding: '10px' }}>
          <SearchInput
            onChange={(e) => {
              this.props.searchData(e.target.value);
              if (e.target.value.length > 0) {
                this.setState({
                  search_mode: true,
                  tab_mode: false
                })
              } else {
                this.setState({
                  search_mode: false,
                  tab_mode: true
                })
              }
            }}
            placeholder={localStorage.getItem('search_agents')}
            style={{ width: "23vw" }}
          />
        </div>
        {this.state.inactive_agents.length}
        {this.state.tab_mode &&
          <div style={{ display: 'flex', justifyContent: "space-around" }}>
            <Tabs activeTab="1" ulClassName="" activityClassName="bg-primary" onClick={(event, tab) => console.log(event, tab)} style={{ width: '24vw' }}>
              <Tab title={`${localStorage.getItem('free')} (${this.state.free_agents.length})`} className="mr-1">
                {this.renderAgents(this.state.free_agents)}
              </Tab>

              <Tab title={`${localStorage.getItem('busy')} (${this.state.busy_agents.length})`} className="mr-1">

                {this.renderAgents(this.state.busy_agents)}

              </Tab>

              <Tab title={`${localStorage.getItem('inactive')}  (${this.state.inactive_agents.length})`} className="mr-1">
                {this.renderAgents(this.state.inactive_agents)}
              </Tab>
            </Tabs>
          </div>
        }

        {this.state.search_mode &&
          this.renderAgents(this.props.filterdDG)
        }
      </React.Fragment>
    );
  }


  renderAgents(agents) {

    return (
      <div
        className={"vertical_scroll"}
        style={{ height: '72vh' }}
      >
        {agents.map((dg, index) => (
          <React.Fragment key={index}>
            <div
              className="card_tab"
              style={
                this.props.selectedDeliveryGuy &&
                  this.props.selectedDeliveryGuy.id === dg.id
                  ? { background: "#f2effb" }
                  : null
              }
              onClick={() => {
                this.props.deployDeliveryGuyPop(dg);
                this.props.mapChangeLocation({ lat: parseFloat(dg.latitude), lng: parseFloat(dg.longitude) })
              }}
            >
              <div className="d-flex justify-content-between">
                <div style={{ width: "18%" }}>
                  <Avatar
                    src={
                      'https://fleet.howincloud.com' +
                      dg.image
                    }
                    name={dg.name}
                    size={50}
                  />
                </div>
                <div style={{ width: "56%" }}>
                  <p className="mb-0">{dg.name}</p>
                  <p className="mb-0 text-muted">
                    {localStorage.getItem('tasks')} : {dg.accept_deliveries.length}
                  </p>
                  <p className="mb-0 text-muted">
                    {dg.phone}
                  </p>
                </div>
                <div style={{ width: "15%" }}>
                  {dg.accept_deliveries.length === 0 && (
                    <Badge color="green">{localStorage.getItem('free')}</Badge>
                  )}
                  {dg.accept_deliveries.length > 0 &&
                    dg.accept_deliveries.length < 4 && (
                      <Badge color="orange">{localStorage.getItem('busy')}</Badge>
                    )}
                  {dg.accept_deliveries.length >= 4 && (
                    <Badge color="red">{localStorage.getItem('danger')}</Badge>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    )

  }



}

export default Agents;