import React, { Component } from "react";
import axios from 'axios';
import { Spinner, Avatar } from 'evergreen-ui';
import { Redirect } from "react-router";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
// const Map = ReactMapboxGl({
//     accessToken:
//       'pk.eyJ1IjoibWF1dGFwYXVtYWxheXNpYSIsImEiOiJja3VxbzNhaXcwYTB1Mm9xOWl6NHAzOGtoIn0.c_MYf10NpHyDd6-Dusx-XQ'
//   });
import { Progress } from 'antd';

import Stepper from 'react-stepper-horizontal';
export class Main extends Component {

  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();
    this.map = React.createRef();

    this.agent = React.createRef();
    this.pickup = React.createRef();
    this.drop = React.createRef();

  }

  state = {
    order: [],
    lat: 10.9760,
    lng: 76.2254,
    zoom: 15,
    count: 0,
    active_stepper: -1
  };

  componentDidMount() {

    mapboxgl.accessToken = 'pk.eyJ1IjoibWF1dGFwYXVtYWxheXNpYSIsImEiOiJjbDF3ZHYyNWYwMG4yM2ltcXN3dDRwMG8yIn0.nflc21hv26oIMYb4Xy4vRg';

    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mautapaumalaysia/cl1wdssce000g14ppyg9u26r8',
      zoom: this.state.zoom
    });


    setTimeout(
      function () {
        this.map.on('load', this.runMarkerFunction(this.state.count));
      }
        .bind(this),
      1000
    );


    this.interval = setInterval(
      () => this.runMarkerFunction(this.state.count),
      30000
    );


  }

  runMarkerFunction(count) {
    this.setState({ count: count + 1 });
    axios.post(`https://fleet.howincloud.com/api/v1/${this.props.match.params.tenant}/task-details/live-track`, {
      task_id: this.props.match.params.task_id
    }).then((response) => {
      if (response.data) {
        this.setState({
          order: response.data,
          order_status: response.data.orderstatus.name
        });

        if (response.data.agent_status == true) {

          this.setState({
            agent_name: response.data.agent.name,
            vehicle_model: response.data.agent.vehicle_model,
            agent_image: response.data.agent.image,
            agent_phone: response.data.agent.user.phone,

          });
        }


        if (response.data.orderstatus.id == 4 || response.data.orderstatus.id == 5) {
          this.setState({
            active_stepper: -1,
          });
        }

        if (response.data.orderstatus.id == 6) {
          this.setState({
            active_stepper: 0,
          });
        }

        if (response.data.orderstatus.id == 7) {
          this.setState({
            active_stepper: 1,
          });
        }

        if (response.data.orderstatus.id == 8) {
          this.setState({
            active_stepper: 2,
          });

          clearInterval(this.interval);
        }
        const agentMarker = document.createElement('div');
        agentMarker.className = 'agent';

        const dropMarker = document.createElement('div');
        dropMarker.className = 'drop';

        const pickupMarker = document.createElement('div');
        pickupMarker.className = 'pickup';

        if (count == 0) {
          if (response.data.agent_status == true && response.data.orderstatus.id != 8) {
            this.agent = new mapboxgl.Marker(agentMarker).setLngLat([response.data.agent_lng, response.data.agent_lat]).addTo(this.map);
          }
          this.drop = new mapboxgl.Marker(dropMarker).setLngLat([response.data.drop_lng, response.data.drop_lat]).addTo(this.map);
          this.pickup = new mapboxgl.Marker(pickupMarker).setLngLat([response.data.pickup_lng, response.data.pickup_lat]).addTo(this.map);
        } else {
          if (response.data.agent_status == true) {
            this.agent.remove();
          }
          this.drop.remove();
          this.pickup.remove();

          if (response.data.agent_status == true &&  response.data.orderstatus.id != 8) {
            this.agent = new mapboxgl.Marker(agentMarker).setLngLat([response.data.agent_lng, response.data.agent_lat]).addTo(this.map);

          }
          this.drop = new mapboxgl.Marker(dropMarker).setLngLat([response.data.drop_lng, response.data.drop_lat]).addTo(this.map);
          this.pickup = new mapboxgl.Marker(pickupMarker).setLngLat([response.data.pickup_lng, response.data.pickup_lat]).addTo(this.map);
        }
        if (response.data.agent_status == true && response.data.orderstatus.id != 8) {
          this.map.flyTo({
            center: [response.data.agent_lng, response.data.agent_lat],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });
        } else {
          if(response.data.orderstatus.id == 8){
            this.map.flyTo({
              center: [response.data.drop_lng, response.data.drop_lat],
              essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
          }else{
            this.map.flyTo({
              center: [response.data.pickup_lng, response.data.pickup_lat],
              essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
          }
          
        }

        //  this.map.center = [response.data.agent_lng, response.data.agent_lat];

        if (this.map.getLayer('route')) {
          this.map.removeLayer('route');
        }
        if (this.map.getSource('route')) {
          this.map.removeSource('route');
        }

        if (response.data.orderstatus.id != 8) {
          this.loadSources(response.data.geo_json)

        }


      }
    });
  }

  loadSources = (data) => {
    this.map.addSource('route', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': data
        }
      }
    });
    this.map.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'route',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#ff5722',
        'line-width': 8
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {

    const query = new URLSearchParams(this.props.location.search);

    return (
      <>
        <div ref={this.mapContainer} className="map-container" />

       
        <div style={{"position":"fixed","top":"0px","backgroundColor":"white","width":"96%","padding":"20px","fontWeight":"500", marginTop:"10px", borderRadius:'8px', left: '2%'}}>
          <div className="p-10 mt-20 text-center" >
										
												<React.Fragment>
													<div style={{ fontWeight: '700' }}> <img 
                          style={{"width":"17px","marginRight":"9px"}}
                          src={'https://cdn-icons-png.flaticon.com/512/3699/3699532.png'}
                           /> {this.state.order.agent_distance > 0 ? (<span>{this.state.order.agent_distance} KM |</span>) : null} ETA in  {this.state.order.agent_duration} Minutes (  {this.state.order.destination_time} )</div>
												</React.Fragment> 

										</div>
        </div> 
        
        <div style={
          { "position": "fixed", "backgroundColor": "rgb(255, 255, 255)", "bottom": "-3px", "left": "0%", "width": "100%", "padding": "10px", "borderRadius": "27px 27px 0px 0px", "filter": "drop-shadow(rgba(0, 0, 0, 0.55) 2px 4px 6px)" }
        }>

          {this.state.active_stepper >= 0 &&
            <div className="d-flex " style={{ padding: '3px 20px' }}>
              <div>
                <Avatar
                  src={
                    'https://fleet.howincloud.com' +
                    this.state.agent_image
                  }
                  name={this.state.agent_name}
                  size={50}
                />
              </div>
              <div style={{ "padding": "2px", "fontWeight": "bold", marginLeft: "9px"}}>
                <div>{this.state.agent_name}</div>
                <div className="text-muted mb-2">{this.state.order.agent.vehicle_model} | {this.state.order.agent.vehicle_number}</div>

                <div className="d-flex">
                {this.props.match.params.tenant != 'mautpau'? 
                <a style={{fontSize: "12px",marginRight: "5px"}} href={`tel:${this.state.agent_phone}`} className="btn btn-primary">
                <img src="https://fleet.howincloud.com/assets/phone.png" alt="Call" style={{width: '20px'}} /> {this.state.agent_phone} 
                  </a> :null}
                {this.props.match.params.tenant == 'mautpau'? 
                <a style={{fontSize: "12px"}} href={`https://wa.me/${this.state.agent_phone}?text=${encodeURI('Hi, I have order from mautapau. My Order ID is  (' + this.props.match.params.task_id + ')')}`} className="btn btn-primary">
                <img src="https://fleet.howincloud.com/assets/whatsapp.png" alt="Whatsapp" style={{width: '20px'}} /> Chat to Agent ! 
                  </a> :null}
                  </div>

              </div>

            </div>
          }
          {this.state.active_stepper < 0 &&
            <div style={{ "padding": "10px", "textAlign": "center", "marginTop": "22px" }}>
              We are searching for the best agent
            </div>}
          <div className="mb-4">
            <Stepper
              activeColor={'#f85722'}
              completeColor={'#52bd95'}
              completeBarColor={'#52bd95'}
              defaultBarColor={'#2e2e2e'}
              barStyle={'solid'}
              circleTop={20}
              size={30}
              steps={
                [
                  { title: 'Assigned', icon: 'https://fleet.howincloud.com/assets/svg/delivery-man.png' },
                  { title: 'Out For Delivery', icon: 'https://fleet.howincloud.com/assets/svg/map.png' },
                  { title: 'Deliverd', icon: 'https://fleet.howincloud.com/assets/svg/delivery.png' }
                ]}
              activeStep={this.state.active_stepper} />
          </div>





        </div>
      </>


    );




  }
}

export default Main;