import React, { Component } from "react";
import axios from 'axios';
import { Spinner } from 'evergreen-ui';
import { Redirect } from "react-router";

export class Main extends Component {
    state = {
      authentication : 30,
      success: false
    }

    componentDidMount(){

      axios
      .post(`https://fleet.howincloud.com/api/v1/${this.props.match.params.tenant}/authenticate-client`,{
        user_id: this.props.match.params.user_id,
        token: this.props.match.params.token,
      })
      .then((response) => {
        if(response.data){
          if(response.data.code !== 401){
            sessionStorage.setItem('client_token',response.data.client_token);
            sessionStorage.setItem('auth_token',response.data.auth_token);
            sessionStorage.setItem('domain',response.data.domain);
            sessionStorage.setItem('ssl', response.data.settings.ssl);
            sessionStorage.setItem('tenant', this.props.match.params.tenant);
            sessionStorage.setItem('is_whie_label', response.data.is_white_label);

            sessionStorage.setItem('delivery_icon_path', response.data.settings.delivery_icon_path);

          }

          this.setState({
            success: response.data.code
          });

        

        }
      });
    }
    render(){


      switch (this.state.success) {
        case true:
          return(
            <Redirect to={'/portal'} />
          )
          break;
        case 401:
            return(
              <div style={{textAlign: 'center', "zIndex":"2147483650","width":"100%","height":"100%","top":"0px","background":"rgb(255 255 255 / 85%)","position":"absolute",padding: 'auto'}}>
               <img style={{"margin":"auto","paddingTop":"48vh","width":"56px","marginBottom":"10px"}} src={'https://fleet.howincloud.com/assets/frontend/icons/padlock.png'} alt="padlock" />
              <p className="text-muted">{localStorage.getItem('authentication_failed')}</p>
            </div>
            );
          break;
        default:
          return (
            <div style={{"zIndex":"2147483650","width":"100%","height":"100%","top":"0px","background":"rgb(255 255 255 / 85%)","position":"absolute",padding: 'auto'}}>
              <Spinner  style={{"margin":"auto","paddingTop":"48vh"}} />
              <p className="text-muted" style={{textAlign: 'center', marginTop: '50px'}}>{localStorage.getItem('howin_fleet_loading')}</p>
            </div>
          );
          break;
      }
    
      
    }
}

export default Main;