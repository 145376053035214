import React, { Component } from "react";

export class SessionExpired extends Component {
    render(){
        return(
            <React.Fragment>
                <div className="error-div">
                        <div className="error-inner-div">
                            <div>
                                <img src="/assets/clock.png" />
                                <h4>{localStorage.getItem('session_expired')}</h4>
                                <hr />
                                <p>{localStorage.getItem('token_expiration')} </p>
                                <a className="btn btn-primary" href={'https://fleet.howincloud.com/client/'+sessionStorage.getItem('tenant')} >Login Again</a>
                            </div>
                        </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SessionExpired;