import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

class MapSelector extends Component {
  state = {
    selectedLocation: null,
    showInfoWindow: false,
  };

  handleMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    const selectedLocation = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    this.setState({
      selectedLocation,
      showInfoWindow: true,
    });
  };

  handleCloseInfoWindow = () => {
    this.setState({
      showInfoWindow: false,
    });
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        onClick={this.handleMapClick}
        initialCenter={{ lat: 0, lng: 0 }}
      >
        {this.state.selectedLocation && (
          <Marker
            position={this.state.selectedLocation}
            name={"Selected Location"}
            onClick={this.handleMapClick}
          />
        )}
        {this.state.showInfoWindow && (
          <InfoWindow
            onClose={this.handleCloseInfoWindow}
            visible={this.state.showInfoWindow}
          >
            <div>
              <p>Selected Location</p>
              <p>Latitude: {this.state.selectedLocation.lat}</p>
              <p>Longitude: {this.state.selectedLocation.lng}</p>
            </div>
          </InfoWindow>
        )}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "YOUR_API_KEY", // Replace with your Google Maps API Key
})(MapSelector);