import React from 'react'
import GoogleMapReact from 'google-map-react';
import { CrossIcon } from 'evergreen-ui';

const Marker = ({ text }) => <div>
    <img src={'/assets/drop_pin.png'} className=" mx-auto" alt="" style={{ height: '30px' }} />
</div>;
const ChooseLocationMap = (props) => {
    const { pickupLocation, dropLocation, type, pikcupType, center, handleMapClick, closeModal } = props
    const defaultLocation = { lat: 28.423964, lng: 45.967696 };
    const location = type === 'pickup' ? pickupLocation : dropLocation;

    const shouldSetOnClick = type === 'pickup' ? pikcupType !== 'warehouse' : true;
    return (
        <div style={{ height: '85vh', width: '100%', position: 'relative' }}>
            <div onClick={()=> closeModal()} style={{ position: 'absolute', top: '-30px', zIndex: 999999, right: '-20px', backgroundColor: 'black', padding: 5, borderRadius: 10 }}>
                <CrossIcon size={30} color='#fff' />
            </div>
            <GoogleMapReact

                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: 'AIzaSyCBmFnNqWbizwxbnfF-6F4hUNp8jh5_RlY' }}
                defaultCenter={{ lat: 28.423964, lng: 45.967696 }}
                defaultZoom={11}
                center={center}


                onClick={shouldSetOnClick ? (e) => {
                    handleMapClick({ lat: e.lat, lng: e.lng, type });
                } : null}
            >
                {pickupLocation && (
                    <Marker
                        lat={pickupLocation.lat}
                        lng={pickupLocation.lng}
                        text={type === 'pickup' ? 'Pickup' : 'Drop'}
                    />
                )}
            </GoogleMapReact>
        </div>
    )
}

export default ChooseLocationMap

