import React, { Component } from "react";

export class Page extends Component {
    render(){
        return(
            <React.Fragment>
                <div style={{width: '100%', margin: 'auto'}}>
                        <h1>404</h1>
                </div>
            </React.Fragment>
        )
    }
}

export default Page;